import { Box, Center, Divider, Heading, InputGroupProps } from '@chakra-ui/react'
import { Control, FieldErrors, FieldValues, UseFormSetValue, UseFormUnregister } from 'react-hook-form'
import {
  BasicField,
  DatepickerField as DatepickerFieldType,
  FlatData,
  FormField,
  FormFieldTypes,
  MultiSelectField,
  NumericField,
  STRING_FIELD_TYPES,
  SelectField as SelectFormField,
  SliderFieldType,
  StyleOptions,
} from '../../interfaces'
import { ValidationUtils } from '../../utils'
import { MarkdownPreview } from '../core/MarkdownPreview'
import { AddressInputField } from './AddressField'
import { ApiLookupField } from './ApiLookupField'
import { BooleanField } from './BooleanField'
import { DatepickerField } from './DatepickerField'
import { GroupedInputsField } from './GroupedInputsField'
import { IconPickerField } from './IconPickerField'
import { InputField } from './InputField'
import { JsonField } from './JsonField'
import { MarkdownField } from './MarkdownField'
import { NumericInputField } from './NumericInputField'
import { SelectField } from './SelectField'
import { SliderField } from './SliderField'
import { TableField } from './TableField'
import { YesNoField } from './YesNoField'

interface FormFieldInstanceProps {
  field: FormField
  control: Control
  isDisabled?: boolean
  errors: FieldErrors
  defaultData?: FlatData
  variant?: InputGroupProps['variant']
  error?: string
  isLabelHidden?: boolean
  hasNoOutline?: boolean
  isDynamicWidth?: boolean
  overrideControlName?: string
  setValue: UseFormSetValue<FieldValues>
  unregister?: UseFormUnregister<FieldValues>
  style: NonNullable<StyleOptions>
  onNavigateByFieldGuid?: (fieldGuid: string) => void
  validationCount: number
}

const Image = ({ url }: { url: string }) => (
  <img src={url} alt="static-image" style={{ maxWidth: '100%', maxHeight: '100%', objectFit: 'contain' }} />
)

export const FormFieldInstance: React.FC<FormFieldInstanceProps> = ({
  field,
  control,
  errors,
  error,
  variant,
  isDisabled,
  isLabelHidden,
  hasNoOutline,
  isDynamicWidth,
  overrideControlName,
  defaultData,
  setValue,
  unregister,
  style,
  onNavigateByFieldGuid,
  validationCount,
}) => {
  if (field.type === FormFieldTypes.Header) {
    return (
      <Box
        height="100%"
        w="100%"
        display="flex"
        textAlign="start"
        justifyContent="center"
        flexDir="column"
        overflow="hidden"
      >
        <Heading size={field.size ?? 'sm'} mb={2} isTruncated color={style.colors?.labelColor}>
          {field.name}
        </Heading>
        {field.hasDivider && <Divider w="100%" borderColor={style.colors?.dividerColor} />}
      </Box>
    )
  }

  if (field.type === FormFieldTypes.Divider) {
    return (
      <Center height="100%" w="100%">
        <Divider w="100%" borderColor={style.colors?.dividerColor} />
      </Center>
    )
  }

  if (field.type === FormFieldTypes.StaticMarkdown) {
    return (
      <Box
        id={`unmand-static-markdown-${field.key}`}
        w="100%"
        height="100%"
        overflow="auto"
        color={style.colors?.inputTextColor}
        textAlign="left"
        fontSize="md"
      >
        <MarkdownPreview content={field.content}></MarkdownPreview>
      </Box>
    )
  }

  if (field.type === FormFieldTypes.StaticImage) {
    return (
      <Center h="100%" w="100%">
        {field.link?.href ? (
          <Box
            h="100%"
            cursor="pointer"
            as={!field.link.isInternal ? 'a' : undefined}
            href={!field.link.isInternal ? field.link.href : undefined}
            target={!field.link.isInternal ? '_blank' : undefined}
            rel="noreferrer"
            onClick={e => {
              if (field.link.isInternal && field.link.href) {
                e.preventDefault()
                e.stopPropagation()
                onNavigateByFieldGuid?.(field.link.href)
              }
            }}
          >
            <Image url={field.url} />
          </Box>
        ) : (
          <Image url={field.url} />
        )}
      </Center>
    )
  }

  const errorMessage =
    validationCount > 0
      ? error ||
        (Object.entries(errors[field.key]?.types ?? {})
          .filter(e => !!e[1])
          .map(([key, message]) => `* ${ValidationUtils.getCustomMessage(key, message)}`)
          .join('\n') as string)
      : ''
  let isReadonly = field?.isReadonly
  let isRequired = field?.isRequired

  if (typeof isReadonly === 'string') {
    isReadonly = isReadonly === 'true'
  }

  if (typeof isRequired === 'string') {
    isRequired = isRequired === 'true'
  }

  if (STRING_FIELD_TYPES.includes(field.type) || field.type === FormFieldTypes.Currency) {
    if (field.type === FormFieldTypes.URL && field.imageUrl) {
      return (
        <Center h="100%" w="100%">
          <Box h="100%" as="a" href={field.imageUrl} target="_blank" rel="noreferrer">
            <Image url={field.imageUrl} />
          </Box>
        </Center>
      )
    }

    return (
      <InputField
        variant={variant}
        type={field.type}
        isDisabled={isDisabled}
        label={field.name}
        formControlName={(field as BasicField).key}
        control={control}
        error={errorMessage}
        isLabelHidden={isLabelHidden}
        hasNoOutline={hasNoOutline}
        helperText={(field as BasicField).helperText}
        placeholder={(field as BasicField).placeholder}
        isRequired={isRequired}
        isReadonly={isReadonly}
        isDynamicWidth={isDynamicWidth}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.JSON) {
    return (
      <JsonField
        isLabelHidden={isLabelHidden}
        formControlName={field.key}
        control={control}
        error={errorMessage}
        isReadonly={isReadonly}
        isDisabled={isDisabled}
        isRequired={isRequired}
        placeholder={field.placeholder}
        helperText={field.helperText}
        title={field.name}
        variant={variant}
        hasNoOutline={hasNoOutline}
        isDynamicWidth={isDynamicWidth}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.NumericInput) {
    return (
      <NumericInputField
        variant={variant}
        label={field.name}
        formControlName={field.key}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        isLabelHidden={isLabelHidden}
        hasNoOutline={hasNoOutline}
        helperText={field.helperText}
        placeholder={field.placeholder}
        isRequired={isRequired}
        isReadonly={isReadonly}
        step={(field as NumericField).step}
        min={(field as NumericField).minimumNumber}
        max={(field as NumericField).maximumNumber}
        isDynamicWidth={isDynamicWidth}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.Slider) {
    return (
      <SliderField
        label={field.name}
        formControlName={field.key}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        isLabelHidden={isLabelHidden}
        helperText={field.helperText}
        isRequired={isRequired}
        isReadonly={isReadonly}
        step={(field as SliderFieldType).step}
        min={(field as SliderFieldType).minimumNumber}
        max={(field as SliderFieldType).maximumNumber}
        prefix={(field as SliderFieldType).prefix}
        suffix={(field as SliderFieldType).suffix}
        restrictedValues={(field as SliderFieldType).restrictedValues}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.Switch || field.type === FormFieldTypes.Checkbox) {
    return (
      <BooleanField
        field={field}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        isReadonly={isReadonly}
        isLabelHidden={isLabelHidden}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.YesNo) {
    return (
      <YesNoField
        field={field}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.IconPicker) {
    return (
      <IconPickerField
        field={field}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        overrideControlName={overrideControlName}
        style={style}
        multiselect={field.multiselect}
        iconBoxSize={field.boxSize}
      />
    )
  }

  if (field.type === FormFieldTypes.Datepicker || field.type === FormFieldTypes.DateRangePicker) {
    return (
      <DatepickerField
        fieldType={field.type}
        variant={variant}
        label={field.name}
        formControlName={field.key}
        isDisabled={isDisabled}
        control={control}
        isLabelHidden={isLabelHidden}
        hasNoOutline={hasNoOutline}
        error={
          field.type === FormFieldTypes.Datepicker
            ? errorMessage
            : errors[field.key]?.[0]?.message ?? errors[field.key]?.[1]?.message
        }
        helperText={field.helperText}
        placeholder={field.placeholder}
        isRequired={isRequired}
        isReadonly={isReadonly}
        futureDatesEnabled={(field as DatepickerFieldType).futureDatesEnabled}
        pastDatesEnabled={(field as DatepickerFieldType).pastDatesEnabled}
        minDate={field.minimumDate ? new Date(field.minimumDate) : undefined}
        maxDate={field.maximumDate ? new Date(field.maximumDate) : undefined}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.Select || field.type === FormFieldTypes.MultiSelect) {
    let maxOptions: number | undefined
    let isMultiSelect = false

    if (field.type === FormFieldTypes.MultiSelect) {
      isMultiSelect = true
      maxOptions = (field as MultiSelectField).maxOptions
    }

    return (
      <SelectField
        isLabelHidden={isLabelHidden}
        isDisabled={isDisabled}
        label={field.name}
        formControlName={field.key}
        control={control}
        error={errorMessage}
        isRequired={isRequired}
        isReadonly={isReadonly}
        placeholder={field.placeholder}
        helperText={field.helperText}
        options={field.options}
        isMultiSelect={isMultiSelect}
        maxOptions={maxOptions}
        overrideControlName={overrideControlName}
        hasNoOutline={hasNoOutline}
        style={style}
        groupedPageRefId={(field as SelectFormField).groupedPageRefId}
      />
    )
  }

  if (field.type === FormFieldTypes.Markdown) {
    return (
      <MarkdownField
        isLabelHidden={isLabelHidden}
        formControlName={field.key}
        control={control}
        error={errorMessage}
        isReadonly={isReadonly}
        isDisabled={isDisabled}
        isRequired={isRequired}
        placeholder={field.placeholder}
        helperText={field.helperText}
        title={field.name}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.Table && unregister) {
    return (
      <TableField
        field={field}
        isDisabled={isDisabled}
        control={control}
        errors={errors}
        unregister={unregister}
        defaultValues={defaultData ?? {}}
        style={style}
        validationCount={validationCount}
      />
    )
  }

  if (field.type === FormFieldTypes.GroupedInputs) {
    return (
      <GroupedInputsField
        field={field}
        isDisabled={isDisabled}
        control={control}
        errors={errors}
        unregister={unregister!}
        defaultValues={defaultData ?? {}}
        style={style}
        isReadonly={isReadonly}
        validationCount={validationCount}
      />
    )
  }

  if (field.type === FormFieldTypes.APILookup) {
    return (
      <ApiLookupField
        field={field}
        label={field.buttonLabel}
        formControlName={field.key}
        isDisabled={isDisabled}
        control={control}
        error={errorMessage}
        helperText={field.helperText}
        overrideControlName={overrideControlName}
        style={style}
      />
    )
  }

  if (field.type === FormFieldTypes.Address) {
    return (
      <AddressInputField
        field={field}
        isDisabled={isDisabled}
        control={control}
        errors={errors}
        setValue={setValue}
        style={style}
      />
    )
  }

  return <>{field.type}</>
}
