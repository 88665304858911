import { Box, Button, Card, Flex, HStack, Spacer, Text } from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import assert from 'assert'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { Control, FieldErrors, FieldValues, GlobalError, UseFormUnregister, useFieldArray } from 'react-hook-form'
import { FiPlus, FiTrash } from 'react-icons/fi'
import { DEFAULT_THEME, EMPTY_FN } from '../../constants'
import { BasicField, FlatData, FormField, FormFieldTypes, StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'
import { FormFieldInstance } from './FormFieldInstance'
import { useConfirmationDialog } from '../../hooks'

const ResponsiveGridLayout = WidthProvider(Responsive)
const TASK_DATA_GRID_COLUMNS = 12
const GRID_ROW_HEIGHT = 80

const cols = {
  lg: TASK_DATA_GRID_COLUMNS,
  md: TASK_DATA_GRID_COLUMNS,
  sm: TASK_DATA_GRID_COLUMNS,
  xs: TASK_DATA_GRID_COLUMNS,
  xxs: TASK_DATA_GRID_COLUMNS,
}

interface GroupedInputsFieldProps {
  field: FormField
  control: Control
  errors: FieldErrors
  unregister: UseFormUnregister<FieldValues>
  defaultValues: FlatData
  isDisabled?: boolean
  isReadonly?: boolean
  style: NonNullable<StyleOptions>
  validationCount: number
}

export const GroupedInputsField: React.FC<GroupedInputsFieldProps> = ({
  field,
  control,
  errors,
  defaultValues,
  isDisabled,
  isReadonly,
  style,
  validationCount,
}) => {
  assert(field.type === FormFieldTypes.GroupedInputs)
  const { colors, inputs } = StyleUtils.getTheme(style)
  const cardBorderColor = inputs.borderColor ?? colors.dividerColor ?? 'gray.200'
  const [hasInit, setHasInit] = useState<boolean>(false)
  const key = field.key
  const { showConfirmDialog: openConfirmationDialog, RenderDialog: ConfirmationDialog } = useConfirmationDialog({
    title: 'Are you sure?',
    message: 'Are you sure you want to remove this? This cannot be undone.',
    primaryBtnLabel: 'Remove',
    secondaryBtnLabel: 'Cancel',
  })

  const { fields, append, remove, replace } = useFieldArray({
    control,
    name: key,
  })

  useEffect(() => {
    if (!isEmpty(defaultValues) && !hasInit) {
      const initialRows = (defaultValues[key] ?? []) as FlatData[]
      initialRows.forEach(row => {
        append(row)
      })
      setHasInit(true)
    }
  }, [defaultValues])

  useEffect(() => {
    if (!fields.length) {
      replace({})
    }
  }, [])

  const fieldLayouts = field?.fields?.map(f => f.layout) ?? []

  return (
    <Flex direction="column" w="100%" h="100%" overflowY="auto" py={4}>
      <ConfirmationDialog />
      <HStack mb={2} color={colors.labelColor}>
        <Text fontWeight="500">{field.name}</Text>
        <Text className="form__optional-indicator">
          {!field.isRequired && inputs.hideRequiredAsterisk && '- Optional'}
        </Text>
        {field.helperText && <InfoTip tooltipText={field.helperText} />}
        {!isReadonly && (
          <Button
            id={`unmand-grouped-input-add-${key}`}
            ml="auto"
            size="xs"
            {...StyleUtils.getGhostButtonStyle(colors.primaryColor)}
            isDisabled={isDisabled}
            leftIcon={<FiPlus size="15" />}
            onClick={() => append({})}
            className="hide-on-print"
          >
            Add
          </Button>
        )}
      </HStack>
      <Text color="red.500" fontSize="sm" textAlign="left">
        {(errors[key] as any)?.message}
      </Text>
      {fields.map((row, rowIdx) => (
        <Card
          variant="outline"
          bg="gray.25"
          key={`grouped-input-row-${row.id}`}
          mt="4"
          height="fit-content"
          borderColor={cardBorderColor}
          sx={{
            '& .form__optional-indicator': {
              display: 'none !important',
            },
          }}
        >
          <ResponsiveGridLayout
            className="layout"
            cols={cols}
            margin={[16, DEFAULT_THEME.layout.padding]}
            rowHeight={GRID_ROW_HEIGHT}
            isDroppable={false}
            isDraggable={false}
            isResizable={false}
            layouts={{
              lg: fieldLayouts,
              md: fieldLayouts,
              sm: fieldLayouts,
              xs: fieldLayouts,
              xxs: fieldLayouts,
            }}
          >
            {field.fields?.map(fieldEntry => {
              const transformedField: BasicField = {
                name: fieldEntry.displayName || fieldEntry.fieldName,
                jsonPath: fieldEntry.fieldName,
                key: fieldEntry.uuid,
                isRequired: false,
                isReadonly: false,
                placeholder: fieldEntry.placeholder,
                type: fieldEntry.fieldType as BasicField['type'],
                layout: fieldEntry.layout,
              }

              return (
                <Box
                  key={fieldEntry.uuid}
                  data-grid={fieldEntry.layout}
                  role="group"
                  overflow={fieldEntry.fieldType === FormFieldTypes.MultiSelect ? 'auto' : 'initial'}
                >
                  <Flex alignItems={fieldEntry.fieldType === FormFieldTypes.Markdown ? 'flex-start' : 'center'} gap={2}>
                    <Box display="contents">
                      <FormFieldInstance
                        variant={DEFAULT_THEME.inputs.variant}
                        isDisabled={isDisabled}
                        field={{
                          ...(fieldEntry.fieldType === FormFieldTypes.Select
                            ? { ...transformedField, options: fieldEntry.options }
                            : transformedField),
                          isReadonly: isReadonly,
                        }}
                        error={((errors[key] as any)?.[rowIdx]?.[fieldEntry.fieldName] as GlobalError)?.message}
                        control={control}
                        errors={errors}
                        isLabelHidden={false}
                        overrideControlName={`${key}[${rowIdx}].${fieldEntry.fieldName}`}
                        style={style ?? {}}
                        setValue={EMPTY_FN}
                        validationCount={validationCount}
                      />
                    </Box>
                  </Flex>
                </Box>
              )
            })}
          </ResponsiveGridLayout>
          <Flex w="100%">
            <Spacer />
            {!isReadonly && (
              <Button
                mr="1"
                mb="1"
                id={`unmand-grouped-input-remove-${key}`}
                size="xs"
                {...StyleUtils.getGhostButtonStyle(colors.primaryColor)}
                isDisabled={isDisabled}
                leftIcon={<FiTrash size="15" />}
                onClick={() => {
                  openConfirmationDialog(() => {
                    remove(rowIdx)
                  })
                }}
                className="hide-on-print"
              >
                Remove
              </Button>
            )}
          </Flex>
        </Card>
      ))}
    </Flex>
  )
}
