import jsonpath from 'jsonpath'
import { merge } from 'lodash'
import { BasicField, FlatData } from '../interfaces'
import { FormsApi } from '../services'
import { collectStyles } from './collect-styles.utils'
import { FormRendererUtils } from './form-renderer.utils'

const mergeFormData = (data: FlatData, formResponse: any, defaultValuesFromUrl: any) => {
  // Merge default payload
  const defaultPayload = formResponse?.form?.defaultPayload ? JSON.parse(formResponse.form.defaultPayload || '{}') : {}
  const formData = merge(data, defaultPayload)

  // Merge URL default values
  const defaultValuesWithNoFields = {}
  Object.keys(defaultValuesFromUrl)
    .filter(key => jsonpath.value(formData, key) === undefined)
    .forEach(key => {
      FormRendererUtils.createNestedObjectByJsonPath(defaultValuesWithNoFields, key, defaultValuesFromUrl[key])
    })

  return merge(formData, defaultValuesWithNoFields)
}

const generateHtmlFile = (blockId: string, attachHtmlFile?: boolean): File | undefined => {
  if (!attachHtmlFile) return undefined

  const htmlString = `
      <html>
        <head>
          <link rel="stylesheet" href="https://unmand-forms.com/form.css" />
          <style>
            ${collectStyles(blockId)}
            @media print {
              body { margin: 0.6cm; }
              .react-grid-item { margin: 0 5px; }
            }
          </style>
        </head>
        <body>
          ${document.getElementById(blockId)?.innerHTML}
        </body>
      </html>
    `

  const blob = new Blob([htmlString], { type: 'text/html' })
  return new File([blob], 'submitted_data.html', { type: 'text/html' })
}

const getFieldStoreValues = (formResponse: any, allFormFields: any[], formData: any) => {
  const fieldStoreValues = {}

  const mappings = formResponse?.form?.swarmDataStoreMapping || []
  for (const mapping of mappings) {
    const field = allFormFields.find(f => f.guid === mapping.field_guid)
    if (field) {
      const value = jsonpath.value(formData, (field as BasicField).jsonPath!)
      if (value) {
        fieldStoreValues[mapping.field_guid] = value
      }
    }
  }

  return fieldStoreValues
}

type FormSubmissionData = {
  formData: any
  password?: string
  prefill?: string
  savedProgress?: string
  htmlFile?: File
  fieldStoreValues: Record<string, any>
}

const submitForm = async (submission: FormSubmissionData, authToken?: string, formId?: string): Promise<string> => {
  if (authToken) {
    return await FormsApi.submitFormPrivate({
      ...submission,
      formId: formId!,
      authToken,
    })
  }

  return await FormsApi.submitFormPublic({
    ...submission,
    formToken: formId!,
  })
}

export const SubmissionUtils = {
  mergeFormData,
  generateHtmlFile,
  getFieldStoreValues,
  submitForm,
}
