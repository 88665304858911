import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputGroupProps,
  InputLeftElement,
  InputRightElement,
  Textarea,
} from '@chakra-ui/react'
import { InfoTip } from '@unmand-systems/components'
import { ChangeEvent, useState } from 'react'
import { Control, Controller } from 'react-hook-form'
import { FiDollarSign, FiExternalLink, FiKey, FiMail, FiPhone } from 'react-icons/fi'
import { NumericFormat, PatternFormat } from 'react-number-format'
import { EMPTY_FN } from '../../constants'
import { useFormAccessibility } from '../../context/FormAccessibilityContext'
import { useFormConditions } from '../../context/FormConditionsContext'
import { FormFieldTypes, StringFormFieldTypes, StyleOptions } from '../../interfaces'
import { StyleUtils } from '../../utils'

interface InputFieldProps {
  formControlName: string
  label: string
  control: Control
  type: StringFormFieldTypes | typeof FormFieldTypes.Currency
  labelTooltip?: string
  error?: string | undefined
  isRequired?: boolean
  isLabelHidden?: boolean
  hasNoOutline?: boolean
  variant?: InputGroupProps['variant']
  isDisabled?: boolean
  isReadonly?: boolean
  isDynamicWidth?: boolean
  helperText?: string
  placeholder?: string
  hideErrorMsg?: boolean
  overrideControlName?: string
  onChange?: (value: string | null) => void // only for address search
  onFocus?: () => void
  onBlur?: () => void
  style: NonNullable<StyleOptions>
}

export const InputField: React.FC<InputFieldProps> = ({
  type,
  formControlName,
  label,
  control,
  error,
  helperText,
  placeholder: placeholderValue,
  isRequired,
  isLabelHidden,
  hasNoOutline,
  variant = 'outlined',
  isDisabled,
  hideErrorMsg,
  isReadonly,
  isDynamicWidth,
  overrideControlName,
  onChange = EMPTY_FN,
  onFocus = EMPTY_FN,
  onBlur = EMPTY_FN,
  style,
}) => {
  const [displayedValue, setDisplayedValue] = useState<string | null | undefined>()
  const [phoneNumCode, setPhoneNumCode] = useState<string>()
  const { evaluateNextPageConditions } = useFormConditions()

  let placeholder = placeholderValue || 'Input value'

  const { colors, inputs } = StyleUtils.getTheme(style)
  const placeholderColor = StyleUtils.getPlaceholderColor(colors.inputBgColor)
  const iconColor = StyleUtils.getIconColor(colors.inputBgColor)
  const isCurrency = type === FormFieldTypes.Currency
  const isTextarea = type === FormFieldTypes.Textarea
  const isSecret = type === FormFieldTypes.Secret
  const isPhoneNumber = type === FormFieldTypes.PhoneNumber
  const { getTabIndex } = useFormAccessibility()

  if (type === FormFieldTypes.URL) {
    placeholder = placeholderValue || 'https://'
  } else if (type === FormFieldTypes.Email) {
    placeholder = placeholderValue || 'Input email'
  }

  if (isReadonly) {
    placeholder = 'Not available'
  }

  return (
    <Controller
      name={overrideControlName ?? formControlName}
      control={control}
      render={({ field }) => {
        const inputWidth =
          isDynamicWidth && (field.value || displayedValue)?.length > 20
            ? `${Math.max((field.value?.length ?? displayedValue?.length ?? 0) * 9.5, 200)}px`
            : '100%'

        return (
          <FormControl
            isInvalid={!!error}
            isRequired={isRequired}
            isDisabled={isDisabled}
            isReadOnly={isReadonly}
            height={isTextarea ? '100%' : 'initial'}
          >
            {!isLabelHidden && (
              <FormLabel display="flex" alignItems="center" gap="1" color={colors.labelColor}>
                {label}
                <span className="form__optional-indicator">
                  {!isRequired && inputs.hideRequiredAsterisk && '- Optional'}
                </span>
                {helperText && <InfoTip tooltipText={helperText} />}
              </FormLabel>
            )}
            <InputGroup height={isTextarea ? '100%' : 'initial'} flexDir="column">
              {getInputIcon(type, iconColor) && <InputLeftElement>{getInputIcon(type, iconColor)}</InputLeftElement>}
              {type === FormFieldTypes.URL && (
                <InputRightElement
                  opacity={!error && field.value ? 1 : 0.6}
                  cursor={!error && field.value ? 'pointer' : 'not-allowed'}
                  onClick={() => {
                    !error && field.value && window.open(field.value, '_blank')
                  }}
                >
                  <FiExternalLink color={iconColor} />
                </InputRightElement>
              )}
              <Input
                {...field}
                id={`unmand-input-${overrideControlName ?? formControlName}`}
                tabIndex={getTabIndex(formControlName)}
                px={variant === 'unstyled' ? 2 : undefined}
                variant={variant}
                resize={'none'}
                type={isSecret ? 'password' : 'text'}
                placeholder={placeholder}
                onWheel={e => (e.target as HTMLElement).blur()}
                value={field.value}
                sx={{
                  '&::placeholder': {
                    color: placeholderColor,
                    fontStyle: 'italic',
                  },
                }}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const val = e.target.value
                  setDisplayedValue(val)
                  field.onChange(val)
                  onChange(val)
                }}
                onFocus={onFocus}
                onBlur={() => {
                  onBlur()
                  evaluateNextPageConditions()
                }}
                {...(hasNoOutline ? { border: 0 } : {})}
                borderRadius={variant !== 'unstyled' ? inputs.borderRadius : 0}
                borderColor={variant === 'outline' ? inputs.borderColor : undefined}
                width={inputWidth}
                isDisabled={isDisabled || isReadonly}
                bg={colors.inputBgColor}
                color={colors.inputTextColor}
                _hover={{ bg: colors.inputBgColor }}
                _focusVisible={{
                  borderColor: colors.primaryColor,
                  boxShadow: `0 0 0 1px ${colors.primaryColor}`,
                }}
                _focus={{
                  borderColor: colors.primaryColor,
                }}
                {...(isCurrency && {
                  as: NumericFormat,
                  getInputRef: field.ref,
                  decimalScale: 2,
                  onChange: undefined,
                  onValueChange: ({ floatValue }) => field.onChange(floatValue),
                  thousandSeparator: ',',
                })}
                {...(isPhoneNumber && {
                  as: PatternFormat,
                  getInputRef: field.ref,
                  format:
                    phoneNumCode && ['02', '03', '07', '08'].includes(phoneNumCode) ? '(##) #### ####' : '#### ### ###',
                  mask: '_',
                  onChange: undefined,
                  onValueChange: ({ value }) => {
                    const code = value.substring(0, 2)
                    code ? setPhoneNumCode(code) : setPhoneNumCode(undefined)
                    field.onChange(value)
                  },
                })}
                {...(isTextarea && {
                  as: Textarea,
                  getInputRef: field.ref,
                  height: `calc(100% - ${error ? '60' : '45'}px)`,
                  minHeight: '40px',
                })}
              />
              {error && !hideErrorMsg && <FormErrorMessage>{error}</FormErrorMessage>}
            </InputGroup>
          </FormControl>
        )
      }}
    />
  )
}

const getInputIcon = (type: FormFieldTypes, color: string): JSX.Element => {
  let icon: JSX.Element

  switch (type) {
    case FormFieldTypes.Currency: {
      icon = <FiDollarSign color={color} />
      break
    }
    case FormFieldTypes.Email: {
      icon = <FiMail color={color} />
      break
    }
    case FormFieldTypes.Secret: {
      icon = <FiKey color={color} />
      break
    }
    case FormFieldTypes.PhoneNumber: {
      icon = <FiPhone color={color} />
    }
  }

  return icon!
}
