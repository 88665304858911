import { isAfter, isBefore, isEqual, parseISO } from 'date-fns'
import { isNil } from 'lodash'
import { Condition, ConditionSource, ConditionSourceState, FieldConditionMap, FormField } from '../interfaces'

const hasMetCondition = (source: ConditionSource, formData: { [key: string]: any }): boolean => {
  const formValue = formData[source.formFieldId]

  const isValueArray = Array.isArray(formValue)

  switch (source.state) {
    case ConditionSourceState.IS_EMPTY:
      return isNil(formValue) || (isValueArray && formValue.length === 0)

    case ConditionSourceState.IS_FILLED:
      return !isNil(formValue) || !!formValue

    case ConditionSourceState.IS_EQUAL_TO: {
      if (isValueArray && formValue.length === 1) {
        return formValue[0] === source.value
      }

      return formValue === source.value
    }

    case ConditionSourceState.IS_NOT_EQUAL_TO: {
      if (isValueArray && formValue.length === 1) {
        return formValue[0] !== source.value
      }

      return formValue !== source.value
    }

    case ConditionSourceState.CONTAINS: {
      if (Array.isArray(source.value)) {
        return source.value.some(val => formValue?.includes(val))
      }
      return formValue?.includes(source.value)
    }

    case ConditionSourceState.DOES_NOT_CONTAIN:
      return !formValue?.includes(source.value)

    case ConditionSourceState.STARTS_WITH:
      return formValue?.startsWith(source.value)

    case ConditionSourceState.DOESNT_START_WITH:
      return !formValue?.startsWith(source.value)

    case ConditionSourceState.ENDS_WITH:
      return formValue?.endsWith(source.value)

    case ConditionSourceState.DOESNT_END_WITH:
      return !formValue?.endsWith(source.value)

    case ConditionSourceState.IS_TRUE:
      return formValue === true

    case ConditionSourceState.IS_FALSE:
      return !formValue

    case ConditionSourceState.LESS_THAN:
      return Number(formValue) < Number(source.value)

    case ConditionSourceState.GREATER_THAN:
      return Number(formValue) > Number(source.value)

    case ConditionSourceState.BEFORE:
      if (!formValue) {
        return false
      }

      return isBefore(parseISO(formValue), parseISO(source.value as string))

    case ConditionSourceState.AFTER:
      if (!formValue) {
        return false
      }

      return isAfter(parseISO(formValue), parseISO(source.value as string))

    case ConditionSourceState.IS_EQUAL_TO_DATE:
      if (!formValue) {
        return false
      }

      return isEqual(parseISO(formValue), parseISO(source.value as string))

    case ConditionSourceState.NOT_EQUAL_TO_DATE:
      if (!formValue) {
        return false
      }

      return !isEqual(parseISO(formValue), parseISO(source.value as string))
    default:
      return false
  }
}

const getNewPageGuid = ({
  change,
  currPageGuid,
  hiddenPageIds,
  pages,
}: {
  change: 'increment' | 'decrement'
  currPageGuid?: string
  pages: string[]
  hiddenPageIds: string[]
}): string => {
  const hiddenPagesSet = new Set(hiddenPageIds)
  let newPageGuid = currPageGuid || pages[0]

  if (currPageGuid) {
    do {
      const currPageIndex = pages.findIndex(pageGuid => pageGuid === newPageGuid)
      if (change === 'increment') {
        newPageGuid = pages[currPageIndex + 1]
      } else {
        currPageIndex > 0 && (newPageGuid = pages[currPageIndex - 1])
      }
    } while (hiddenPagesSet.has(newPageGuid))
  }
  return newPageGuid
}

const generateFieldConditionMap = (conditions: Condition[], formFields: FormField[]): FieldConditionMap => {
  const fieldConditionMap: FieldConditionMap = {}

  // Create a lookup Map for form fields based on formFieldId
  const formFieldSet = new Set(formFields.map(field => field.guid))

  // Create a Map for fast condition lookups by condition.guid
  const conditionMap = new Map(conditions.map(condition => [condition.guid, condition]))

  conditions.forEach(condition => {
    condition.source.forEach(source => {
      const isFormFieldExisting = formFieldSet.has(source.formFieldId)
      if (isFormFieldExisting) {
        // Initializes a map structure for faster lookup.
        if (!fieldConditionMap[source.formFieldId]) {
          fieldConditionMap[source.formFieldId] = []
        }
        fieldConditionMap[source.formFieldId].push(condition)
      } else {
        // Removes the missing form field from the conditions source.
        const matchingCondition = conditionMap.get(condition.guid)
        if (matchingCondition) {
          matchingCondition.source = matchingCondition.source.filter(s => s.formFieldId !== source.formFieldId)
        }
      }
    })
  })

  return fieldConditionMap
}

export const ConditionUtils = {
  hasMetCondition,
  getNewPageGuid,
  generateFieldConditionMap,
}
