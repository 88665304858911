import { Box, Button, Card, Center, Icon, Text, VStack } from '@chakra-ui/react'
import { Prose } from '@unmand-systems/components'
import { LuCheckCircle2, LuDownload } from 'react-icons/lu'
import { useFormState } from '../../context/FormStateContext'
import { MarkdownPreview } from '../core/MarkdownPreview'

interface SuccessPageResponse {
  messageTitle: string
  messageDescription: string
  downloadPdfEnabled?: boolean
}

type SuccessPageProps = SuccessPageResponse & {
  formName?: string
  pdfUrl?: string
  onPrintPdf: () => void
}

export const SuccessPage: React.FC<SuccessPageProps> = ({
  messageTitle,
  messageDescription,
  pdfUrl,
  formName,
  onPrintPdf,
  downloadPdfEnabled,
}) => {
  // we might not need this anymore
  // eslint-disable-next-line
  const handleDownloadClick = () => {
    if (pdfUrl) {
      const downloadLink = document.createElement('a')
      downloadLink.href = pdfUrl
      downloadLink.download = `${formName}-response.pdf`
      downloadLink.click()
    }
  }
  const { isPrintMode } = useFormState()

  const SuccessMessage = (
    <Box className="hide-on-print">
      <Card variant="outline" p="10" bg="green.50" my="6">
        <VStack textAlign="center">
          <Icon as={LuCheckCircle2} boxSize={10} color="green.500" />
          <Text fontSize="xl" fontWeight="semibold" color="green.500">
            {messageTitle}
          </Text>
          <Prose color="gray.600">
            <MarkdownPreview content={messageDescription} className="" />
          </Prose>
        </VStack>
      </Card>
      {downloadPdfEnabled && (
        <Center w="100%">
          <Button
            leftIcon={<LuDownload />}
            variant="ordinaryOutline"
            _hover={{ bg: 'gray.50' }}
            _active={{ bg: 'gray.100' }}
            onClick={onPrintPdf}
          >
            Download response as PDF
          </Button>
        </Center>
      )}
    </Box>
  )

  if (isPrintMode) {
    return SuccessMessage
  }

  return (
    <VStack
      flex="1"
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      gap={6}
      h="100%"
      className="hide-on-print"
    >
      {SuccessMessage}
    </VStack>
  )
}
