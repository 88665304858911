import { Box, Card, Center, Container, Flex, VStack, useToast } from '@chakra-ui/react'
import { Loader } from '@unmand-systems/components'
import jsonpath from 'jsonpath'
import { cloneDeep, isEmpty, isNil } from 'lodash'
import React, { MouseEvent, useEffect, useRef, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { MultipleFieldErrors } from 'react-hook-form'
import { EMPTY_FN, GRID_ROW_HEIGHT, MIN_FORM_WIDTH, TASK_DATA_GRID_COLUMNS, cols } from '../constants'
import { GoogleMapsProvider } from '../context'
import { FormAccessibilityProvider } from '../context/FormAccessibilityContext'
import { useFormConditions } from '../context/FormConditionsContext'
import { CustomFieldError, useFormState } from '../context/FormStateContext'
import {
  APIFormField,
  ErrorResponse,
  FlatData,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  FormResponse,
  HeaderField,
  Page,
  ProgressComponentType,
} from '../interfaces'
import { UnmandFormComponent } from '../interfaces/unmand-form-component'
import { FormsApi } from '../services'
import {
  ConditionUtils,
  ErrorUtils,
  FormBuilderUtils,
  FormRendererUtils,
  GeneralUtils,
  GroupedPageUtils,
  StyleUtils,
  SubmissionUtils,
  ValidationUtils,
} from '../utils'
import FormNavigationAndSubmit from './FormNavigationAndSubmit'
import { GroupedPageTabs } from './GroupedPageTabs'
import { SubmittedFormDetails } from './SubmittedFormDetails'
import { FormStepper } from './core/FormStepper'
import { NAVBAR_HEIGHT, TopNav } from './core/TopNav'
import { FormFieldInstance } from './field-inputs'
import {
  DisabledPage,
  DisabledPageProps,
  ErrorPage,
  NotFound,
  PasswordPage,
  SavedProgressPage,
  SuccessPage,
  UnauthorizedPage,
} from './screen-states'

const RECAPTCHA_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY
const DEFAULT_SUCCESS_TITLE = 'Successfully submitted!'
const DEFAULT_SUCCESS_BODY = 'You have successfully submitted this form.'

const ResponsiveGridLayout = WidthProvider(Responsive)

const SUBMITTED_DATA_BLOCK_ID = 'unmand-submitted-data'

interface SuccessPage {
  messageTitle: string
  messageDescription: string
}

interface FormProps {
  unmandForm: UnmandFormComponent
  scriptReference: HTMLOrSVGScriptElement | null
}

export const Form: React.FC<FormProps> = ({ unmandForm, scriptReference }) => {
  const {
    formFields,
    fieldPages,
    fieldPagesSOT,
    page,
    latestFormData,
    setFieldPages,
    setPage,
    setFormFields,
    setFieldPagesSOT,
    scrollFieldIntoView,
    hiddenPageIds,
    pages,
    setPages,
    onAddEntryToGroupedPage,
    isPrintMode,
    setIsPrintMode,
    isReadOnly,
    setIsReadOnly,
    isLoading,
    setIsLoading,
    validateForm,
    sortedPages,
    setConditions,
    formMethods: {
      handleSubmit,
      setValue,
      trigger,
      getValues,
      clearErrors,
      unregister,
      setError,
      control,
      formState: { errors, isDirty, isSubmitSuccessful, isSubmitting },
    },
  } = useFormState()

  const { evaluatePageConditions, setFieldConditionMap, setForceTrigger, validationCount, setValidationCount } =
    useFormConditions()

  const [hasPassword, setHasPassword] = useState<boolean>()
  const [disabledPage, setDisabledPage] = useState<DisabledPageProps | null>(null)
  const [successPage, setSuccessPage] = useState<SuccessPage | null>(null)
  const [formId, setFormId] = useState<string>()
  const [authToken, setAuthToken] = useState<string>()
  const [password, setPassword] = useState<string>()

  const [isFromScript, setIsFromScript] = useState<boolean>(false)
  const [formResponse, setFormResponse] = useState<FormResponse | null>(null)
  const [isInvalidPassword, setIsInvalidPassword] = useState<boolean>(false)
  const [isUnauthorized, setIsUnauthorized] = useState<boolean>(false)
  const [isPreview, setIsPreview] = useState<boolean>(false)
  const [isNotFound, setIsNotFound] = useState<boolean>(false)
  const [formProgressSaveGuid, setFormProgressSaveGuid] = useState<string | null>(null)
  const [errorMsg, setErrorMsg] = useState<string>()
  const [isCaptchaEnabled, setIsCaptchaEnabled] = useState<boolean>(false)
  const [hasValidRecaptcha, setHasValidRecaptcha] = useState<boolean>(false)
  const [pdfUrl, setPdfUrl] = useState<string>()
  const [prefillId, setPrefillId] = useState<string>()
  const [prefillData, setPrefillData] = useState<any>()
  const [savedProgressId, setSavedProgressId] = useState<string>()
  const [savedProgressData, setSavedProgressData] = useState<any>()
  const [selectedTabIndex, setSelectedTabIndex] = useState<number>(0)
  const [hasInit, setHasInit] = useState<boolean>(false)
  const [defaultValuesFromUrl, setDefaultValuesFromUrl] = useState<{ [jsonPathKey: string]: any }>({})
  const [isStepperInitialized, setIsStepperInitialized] = useState<boolean>(false)

  const manualSubmitBtnRef = useRef<HTMLButtonElement>(null)
  const toast = useToast()

  const isMobile = GeneralUtils.isMobileBrowser()
  const pageKeys = GeneralUtils.sortPages(formResponse?.pages ?? []).map(page => page.guid)
  const totalPages = pageKeys.length
  const fieldLayouts =
    (page &&
      fieldPages[page]?.map(field => {
        let layout = field.layout!

        if (isMobile) {
          layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
        }

        return layout
      })) ??
    []

  const { style } = formResponse ?? {}

  const currPageGuid = pageKeys.find(key => key === page)
  const isLastPage = currPageGuid === pageKeys.filter(key => !hiddenPageIds.includes(key)).at(-1) || !currPageGuid
  const progress = ((pageKeys.findIndex(guid => guid === currPageGuid) + 1) / (totalPages + 1)) * 100
  const activePage = pages ? pages.find(p => p.guid === page) || pages[0] : null
  const allFormFields: FormField[] = Object.values(fieldPages).flat()
  const errorKeys = Object.keys(errors ?? {})

  const hasRunOnce = useRef(false)
  const containerRef = useRef<HTMLDivElement>(null)

  unmandForm.getData = () => {
    return latestFormData
  }

  useEffect(() => {
    initData()
  }, [])

  const initData = () => {
    if (hasRunOnce.current) {
      return
    }
    let formId = ''
    let authToken = ''
    let isPreview = false
    let prefillId = ''
    let savedProgressId = ''

    if (scriptReference && !GeneralUtils.isLoadedFromUrl(scriptReference?.getAttribute('src'))) {
      formId = scriptReference.getAttribute('form-id')!
      authToken = scriptReference.getAttribute('auth-token')!
      isPreview = scriptReference.getAttribute('is-preview') === 'true'
      prefillId = scriptReference.getAttribute('prefill-id')!
      savedProgressId = scriptReference.getAttribute('saved-progress-id')!

      if (prefillId && savedProgressId) {
        throw new Error(
          'Invalid script: Cannot specify both "prefill-id" and "saved-progress-id" attributes simultaneously',
        )
      }

      setIsFromScript(true)
    } else {
      const urlParams = new URLSearchParams(window.location.search)
      formId = window.location.pathname.replace('/', '')
      authToken = urlParams.get('token')!
      isPreview = urlParams.get('is-preview') === 'true'
      prefillId = urlParams.get('prefill')!
      savedProgressId = urlParams.get('saved-progress')!

      if (prefillId && savedProgressId) {
        throw new Error('Invalid URL: Cannot specify both "prefill" and "saved-progress" parameters simultaneously')
      }

      const defaultValues = {}
      urlParams.forEach((value, key) => {
        if (!['token', 'prefill', 'is-preview', 'saved-progress'].includes(key)) {
          defaultValues[key] = value
        }
      })

      setDefaultValuesFromUrl(defaultValues)
    }

    setFormId(formId as string)
    setAuthToken(authToken as string)
    setIsPreview(isPreview)
    setPrefillId(prefillId)
    setSavedProgressId(savedProgressId)
    fetchFormDetails(formId, authToken, isPreview)

    hasRunOnce.current = true
  }

  const fetchPrefilledOrSavedData = async (
    pageList: Page[],
    prefillOrSaveId: string,
    formFields: FormField[],
    type: 'prefill' | 'save' = 'prefill',
  ) => {
    const actions = {
      save: {
        fetch: FormsApi.getSavedProgressData,
        setState: setSavedProgressData,
      },
      prefill: {
        fetch: FormsApi.getPrefilledData,
        setState: setPrefillData,
      },
    }

    try {
      const { fetch, setState } = actions[type]
      const data = await fetch(prefillOrSaveId)
      setState(data)

      let latestFields = handleGroupedPagePrefills(pageList, data)

      if (!latestFields.length) {
        latestFields = formFields
      }

      const updatedData = FormRendererUtils.updateFormDataByJson(data, latestFields)

      Object.entries(updatedData).forEach(([key, value]) => setValue(key, value))

      FormRendererUtils.setDefaultSliderValue(updatedData, formFields, setValue)
    } catch (e: any) {
      if (e.message) {
        toast({
          description: e.message,
          status: 'warning',
          position: 'top',
          isClosable: true,
          duration: 1400,
          variant: 'subtle',
        })
      }
      console.error(e)
    }
  }

  const handleGroupedPagePrefills = (pageList: Page[], prefillData: any) => {
    let latestFieldPages: FormFieldPages | null = null
    let latestFormFields: FormField[] = []

    pageList
      .filter(p => p.isGrouped && p.parentJsonpath)
      .forEach(page => {
        const val = jsonpath.value(prefillData, page.parentJsonpath!)

        if (Array.isArray(val) && val.length > 1) {
          val.slice(1).forEach((_, i) => {
            latestFieldPages = onAddEntryToGroupedPage(page, latestFieldPages, i + 1) ?? {}
          })
        }
      })

    if (latestFieldPages) {
      latestFormFields = Object.values(latestFieldPages).flat() as FormField[]
    }

    return latestFormFields
  }

  const fetchFormDetails = async (formId: string, authToken: string, isPreview: boolean) => {
    setIsLoading(true)
    setHasPassword(false)
    let response: FormResponse

    try {
      if (authToken && !isPreview) {
        response = await FormsApi.getFormPrivate(formId, authToken, password)
      } else if (authToken && isPreview) {
        response = await FormsApi.getFormPreview(formId, authToken, password)
      } else {
        response = await FormsApi.getFormPublic(formId, password)
      }

      const formResponse = GroupedPageUtils.parseFormResponse(response)
      setFormResponse(formResponse)
      document.title = formResponse.form.name

      if (formResponse.form.faviconUrl) {
        let link = document.querySelector("link[rel~='icon']") as any

        if (!link) {
          link = document.createElement('link')
          link.rel = 'icon'
          document.getElementsByTagName('head')[0].appendChild(link)
        }
        link.href = formResponse.form.faviconUrl
      }

      setIsCaptchaEnabled(formResponse.form.captchaEnabled)
      setSuccessPage({
        messageTitle: formResponse.form.completedMessageTitle || DEFAULT_SUCCESS_TITLE,
        messageDescription: formResponse.form.completedMessageBody || DEFAULT_SUCCESS_BODY,
      })
      const fieldPagesResponse = FormBuilderUtils.convertFormPages(formResponse.layout)

      setFieldPages(fieldPagesResponse)
      setFieldPagesSOT(cloneDeep(fieldPagesResponse))

      setPage(formResponse.pages[0].guid)
      setPages(formResponse.pages)
      setFormFields(fieldPagesResponse[formResponse.pages[0].guid])
      setIsReadOnly(formResponse.form.readOnly)

      const formFields: APIFormField[] = Object.values(formResponse.layout).flat()
      const conditions = cloneDeep(formResponse.conditions)

      setFieldConditionMap(ConditionUtils.generateFieldConditionMap(conditions ?? [], formFields as any as FormField[]))
      setConditions(conditions)

      setHasInit(true)
    } catch (e: unknown) {
      const error = e as ErrorResponse

      if (error.status === 418) {
        setHasPassword(true)
        setIsInvalidPassword(!!password)
      } else if (error.status === 410) {
        setDisabledPage({
          messageTitle: error.messageTitle || 'This form is no longer accepting submissions',
          messageDescription: error.messageDescription || 'Please contact the form owner for details.',
        })
      } else if (error.status === 401) {
        setIsUnauthorized(true)
      } else if (error.status === 404) {
        setIsNotFound(true)
      } else {
        setErrorMsg(error.message!)
      }
    } finally {
      setIsLoading(false)
    }
  }

  const proceedToPage = (newPageGuid: string, formFields: FormField[]) => {
    setPage(newPageGuid)
    setFormFields(formFields)
    setValidationCount(0)
  }

  const onPageChange = async (
    change: 'increment' | 'decrement',
    pageGuid?: string,
    e?: MouseEvent<HTMLElement>,
    isFromStepper?: boolean,
  ) => {
    if (e) {
      e.preventDefault()
      clearErrors()
      isFromStepper = true
    }

    containerRef.current?.scrollTo({ top: 0 })

    let newPageGuid =
      pageGuid ??
      ConditionUtils.getNewPageGuid({
        change,
        currPageGuid,
        hiddenPageIds,
        pages: pageKeys,
      })

    setForceTrigger(true)

    if (change === 'decrement') {
      proceedToPage(newPageGuid, fieldPages[newPageGuid] ?? [])
      return
    }

    if (change === 'increment') {
      await triggerFormValidation()

      const { latestPages, nextPage } = evaluatePageConditions(newPageGuid)
      newPageGuid = nextPage

      if (newPageGuid === undefined && isLastPage) {
        manualSubmitBtnRef.current?.click()
      }

      if (!newPageGuid) return

      const validationErrors = await validateForm(newPageGuid)
      const sortedPagesOrder = sortedPages.map(page => page.guid)
      const associatedPage = getAssociatedPage(validationErrors, sortedPagesOrder)

      if (associatedPage) {
        const pageWithErrorIdx = associatedPage ? sortedPagesOrder.indexOf(associatedPage) : Infinity
        const newPageIdx = newPageGuid ? sortedPagesOrder.indexOf(newPageGuid) : Infinity

        if (associatedPage === page && !isFromStepper) return

        if ((pageWithErrorIdx <= newPageIdx && isFromStepper) || associatedPage === page) {
          setPage(associatedPage)
          setFormFields(latestPages?.[associatedPage] ?? [])

          // Trigger errors if validation fails
          if (!(await triggerFormValidation())) {
            setTimeout(onDisplayErrors, 0)
          }
        } else {
          proceedToPage(newPageGuid, latestPages?.[newPageGuid] ?? [])
        }
      } else {
        proceedToPage(newPageGuid, latestPages?.[newPageGuid] ?? [])
      }
    }
  }

  const getAssociatedPage = (validationErrors: string[], sortedPagesOrder: string[]) => {
    const hasFormValidationError = validationErrors.length
    const firstErrorKeyWithTypes = errorKeys?.find(key => !isEmpty(errors[key]?.types))

    if (hasFormValidationError || firstErrorKeyWithTypes) {
      const validationErrorAssociatedPage = hasFormValidationError
        ? FormRendererUtils.findPageGuidByFieldGuid(validationErrors[0], fieldPages)
        : undefined

      const errorAssociatedPage = firstErrorKeyWithTypes
        ? FormRendererUtils.findPageGuidByFieldGuid(firstErrorKeyWithTypes, fieldPages)
        : undefined

      const validationErrorPageIdx = validationErrorAssociatedPage
        ? sortedPagesOrder.indexOf(validationErrorAssociatedPage)
        : Infinity

      const errorPageIdx = errorAssociatedPage ? sortedPagesOrder.indexOf(errorAssociatedPage) : Infinity

      return validationErrorPageIdx < errorPageIdx
        ? validationErrorAssociatedPage // Validation error is ahead
        : errorPageIdx < validationErrorPageIdx
        ? errorAssociatedPage // First error is ahead
        : validationErrorAssociatedPage || errorAssociatedPage // Both are undefined
    }
  }

  const triggerFormValidation = async (): Promise<boolean> => {
    const allFieldKeys = allFormFields.flatMap((field: { type: FormFieldTypes; key: string }) =>
      field.type === FormFieldTypes.Address ? FormBuilderUtils.getRequiredAddressKeys(field.key) : field.key,
    ) as string[]

    // Store current custom (conditional) errors
    const currentErrors = { ...errors }

    // Trigger validation
    const isValid = await trigger(allFieldKeys)

    // Re-set custom (conditional) errors if they exist
    Object.entries(currentErrors).forEach(([key, error]) => {
      if ((error as CustomFieldError)?.customError) {
        setError(key, { types: error?.types as MultipleFieldErrors })
      }
    })

    const hasErrors = errorKeys.length > 0
    const hasErrorObjects = errorKeys.some(
      key => (errors[key] as CustomFieldError)?.customError && !isEmpty(errors[key]?.types),
    )

    return isValid && !(hasErrors && hasErrorObjects)
  }

  const onNavigateByFieldGuid = (fieldGuid: string) => {
    const toPageGuid = FormRendererUtils.findPageGuidByFieldGuid(fieldGuid, fieldPagesSOT)
    if (toPageGuid !== currPageGuid) {
      onPageChange('increment', toPageGuid)
    } else {
      scrollFieldIntoView(fieldGuid)
    }
  }

  const onSubmitForm = async (data: FlatData) => {
    const hasValidationError = !(await triggerFormValidation())
    const hasError = errorKeys?.some(key => !isEmpty(errors[key]?.types))

    if (!hasValidationError && !hasError) {
      // only invoke handleSubmit if there are no errors, otherwise formState.isSubmitSuccessful will be set
      handleSubmit(async () => {
        setErrorMsg('')

        try {
          const formData = SubmissionUtils.mergeFormData(data, formResponse, defaultValuesFromUrl)
          const htmlFile = SubmissionUtils.generateHtmlFile(SUBMITTED_DATA_BLOCK_ID, formResponse?.form.attachHtmlFile)
          const fieldStoreValues = SubmissionUtils.getFieldStoreValues(formResponse, allFormFields, formData)

          const submission = {
            formData,
            password,
            prefill: prefillData ? prefillId : undefined,
            savedProgress: savedProgressData ? savedProgressId : undefined,
            htmlFile,
            fieldStoreValues,
          }
          const pdfUrl = await SubmissionUtils.submitForm(submission, authToken, formId)
          setPdfUrl(pdfUrl)
        } catch (e) {
          const error = e as ErrorResponse
          console.error(error)
          setErrorMsg(error.message)
        }
      })()
    }
  }

  // if any form fields have defaultValue, set them
  const initializeDefaultValues = async () => {
    const allFormFields = Object.values(fieldPages).flat()

    allFormFields.forEach(field => {
      if ('defaultValue' in field && !isNil(field.defaultValue) && field.defaultValue !== '') {
        setValue(field.key, field.defaultValue)
      }

      if (field.type === FormFieldTypes.Datepicker && field.isDefaultDateToday) {
        setValue(field.key, new Date().toISOString())
      }
    })

    if (!isEmpty(defaultValuesFromUrl)) {
      const objectFromJsonpath = {}

      for (const key in defaultValuesFromUrl) {
        if (defaultValuesFromUrl.hasOwnProperty(key)) {
          FormRendererUtils.createNestedObjectByJsonPath(objectFromJsonpath, key, defaultValuesFromUrl[key])
        }
      }

      const updatedData = FormRendererUtils.updateFormDataByJson(objectFromJsonpath, allFormFields)

      Object.entries(updatedData)
        .filter(entry => entry[1] !== undefined)
        .forEach(([key, value]) => setValue(key, value))
    }

    FormRendererUtils.setDefaultSliderValue(getValues(), formFields, setValue)

    const fields = Object.values(fieldPages).flatMap(array => array)

    if (prefillId) {
      await fetchPrefilledOrSavedData(pages, prefillId, fields)
    }

    if (savedProgressId) {
      await fetchPrefilledOrSavedData(pages, savedProgressId, fields, 'save')
    }

    if (currPageGuid) evaluatePageConditions(currPageGuid)

    setTimeout(() => {
      setIsStepperInitialized(true)
    }, 1500) // This is added to prevent the stepper from flickering on page load
  }

  const onDisplayErrors = () => {
    toast.closeAll()

    const errorItems: {
      message?: string
      key: string
    }[] = Object.entries(errors ?? {}).flatMap(([key, error]) =>
      Object.entries((error?.types ?? {}) as MultipleFieldErrors).map(([k, v]) => ({
        key,
        message: ValidationUtils.getCustomMessage(k, v as string),
      })),
    )

    if (errorItems.length > 6) return

    errorItems.forEach(e => ErrorUtils.showErrorToast(allFormFields, e, toast, scrollFieldIntoView))
  }

  useEffect(() => {
    initializeDefaultValues()
  }, [hasInit])

  useEffect(() => {
    const handler = (event: BeforeUnloadEvent) => {
      event.preventDefault()
    }

    if (isDirty && !isSubmitSuccessful) {
      window.addEventListener('beforeunload', handler)
      return () => {
        window.removeEventListener('beforeunload', handler)
      }
    }
    return EMPTY_FN
  }, [isDirty, isSubmitSuccessful])

  if (isLoading) {
    return (
      <Center w="100vw" h="100vh" bg="gray.50">
        <Loader size="lg"></Loader>
      </Center>
    )
  }

  if (errorMsg) {
    return <ErrorPage errorMsg={errorMsg} />
  }

  if (isNotFound) {
    return <NotFound />
  }

  if (disabledPage) {
    return <DisabledPage {...disabledPage} />
  }

  if (isUnauthorized) {
    return <UnauthorizedPage />
  }

  if (hasPassword) {
    return (
      <PasswordPage
        isInvalidPassword={isInvalidPassword}
        setIsInvalidPassword={setIsInvalidPassword}
        password={password}
        setPassword={setPassword}
        onSubmit={() => {
          fetchFormDetails(formId!, authToken!, isPreview)
        }}
      />
    )
  }

  const { colors, inputs, layout } = StyleUtils.getTheme(style ?? {})
  const progressComponentType = formResponse?.form?.progressComponentType
  const displayedFields = activePage?.isGrouped ? formFields.filter(f => f.listIndex === selectedTabIndex) : formFields
  return (
    <Flex
      className="unmand-form-wrapper"
      flexDir="column"
      position="relative"
      minW={{ base: '0px', md: MIN_FORM_WIDTH }}
      w="100%"
      minH="100vh"
      {...(isPrintMode
        ? {
            maxW: '800px',
            margin: '0 auto',
          }
        : {})}
    >
      {!isFromScript && !isPrintMode && (
        <TopNav
          title={formResponse?.form?.name}
          logoUrl={formResponse?.form?.logoUrl}
          progress={isSubmitSuccessful ? 100 : progress}
          style={style}
          progressComponentType={progressComponentType ?? ProgressComponentType.STEPPER}
          isReadOnly={isReadOnly}
        />
      )}
      {formProgressSaveGuid ? (
        <SavedProgressPage guid={formProgressSaveGuid} />
      ) : isSubmitSuccessful && !isSubmitting && successPage ? (
        <SuccessPage
          {...successPage}
          formName={`${formResponse?.form.name}`}
          downloadPdfEnabled={formResponse?.form.submissionDownloadEnabled}
          pdfUrl={pdfUrl}
          onPrintPdf={() => {
            setIsPrintMode(true)
            setTimeout(() => {
              print()
            }, 500)
          }}
        />
      ) : !isPrintMode ? (
        <Box
          ref={containerRef}
          textAlign="center"
          fontSize="xl"
          p={{ base: 1, md: '6' }}
          minW={{ base: '0px', md: MIN_FORM_WIDTH }}
          height={`calc(100vh - ${NAVBAR_HEIGHT})`}
          overflowY="auto"
          sx={{
            '& .chakra-form__required-indicator': {
              display: inputs.hideRequiredAsterisk ? 'none' : 'block',
              ml: 0,
            },
            '& .chakra-form__error-message': {
              whiteSpace: 'pre-wrap',
              textAlign: 'left',
              fontSize: 'xs',
              mt: '6px',
            },
            '& .form__optional-indicator': {
              display: 'inline-block',
              color: 'gray.500',
              fontSize: 'xs',
              fontStyle: 'italic',
              fontWeight: 400,
            },
          }}
        >
          <GoogleMapsProvider pages={fieldPagesSOT}>
            <FormAccessibilityProvider fields={formFields}>
              <Container py={{ base: '0', md: '4' }} px={{ base: '0', md: 'inherit' }}>
                <Card
                  variant="outline"
                  p={{ base: '0', md: '4' }}
                  borderRadius={layout.borderRadius}
                  bg={layout.backgroundColor}
                  color={colors.labelColor}
                >
                  {progressComponentType === ProgressComponentType.STEPPER && isStepperInitialized && (
                    <FormStepper
                      showName={!!formResponse?.form.showStepName}
                      style={style}
                      pages={formResponse?.pages.filter(page => !hiddenPageIds.includes(page.guid))}
                      currPage={formResponse?.pages.find(p => p.guid === page)}
                      onPageChange={onPageChange}
                      isReadOnly={formResponse?.form.readOnly}
                    />
                  )}
                  <form
                    noValidate
                    onSubmit={async e => {
                      e.preventDefault()
                      if (isPreview) return
                      const updatedData = FormRendererUtils.updateDataByJsonpaths(getValues(), fieldPages, {}, EMPTY_FN)
                      await onSubmitForm(updatedData)
                    }}
                  >
                    <VStack alignItems="start">
                      {activePage && activePage.isGrouped && (
                        <GroupedPageTabs
                          style={style}
                          activePage={activePage}
                          selectedTabIndex={selectedTabIndex}
                          setSelectedTabIndex={setSelectedTabIndex}
                        />
                      )}
                      <Box w="100%">
                        <ResponsiveGridLayout
                          className="layout"
                          cols={cols}
                          margin={[16, layout.padding]}
                          rowHeight={GRID_ROW_HEIGHT}
                          isDraggable={false}
                          isResizable={false}
                          layouts={{
                            lg: fieldLayouts,
                            md: fieldLayouts,
                            sm: fieldLayouts,
                            xs: fieldLayouts,
                            xxs: fieldLayouts,
                          }}
                        >
                          {displayedFields
                            .filter(f => !(f as HeaderField).showOnlyOnPrint)
                            .map(f => {
                              const field = f

                              let layout = field.layout
                              if (isMobile) {
                                layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
                              }

                              return (
                                <Box
                                  id={`formfield-${field.guid}`}
                                  key={field.key}
                                  data-grid={layout}
                                  role="group"
                                  overflow={field.type === FormFieldTypes.MultiSelect ? 'auto' : 'initial'}
                                >
                                  <Flex
                                    alignItems={field.type === FormFieldTypes.Markdown ? 'flex-start' : 'center'}
                                    gap={2}
                                    height="100%"
                                  >
                                    <Box display="contents">
                                      <FormFieldInstance
                                        variant={inputs.variant}
                                        isDisabled={false}
                                        field={field}
                                        control={control}
                                        errors={errors}
                                        isLabelHidden={false}
                                        defaultData={{}}
                                        unregister={unregister}
                                        setValue={setValue}
                                        style={style ?? {}}
                                        onNavigateByFieldGuid={onNavigateByFieldGuid}
                                        validationCount={validationCount}
                                      />
                                    </Box>
                                  </Flex>
                                </Box>
                              )
                            })}
                        </ResponsiveGridLayout>
                      </Box>
                    </VStack>
                    {isCaptchaEnabled && (
                      <Flex w="100%" justifyContent="center" pt="4" display={isLastPage ? 'flex' : 'none'}>
                        <ReCAPTCHA
                          sitekey={RECAPTCHA_KEY}
                          onChange={value => {
                            setHasValidRecaptcha(!!value)
                          }}
                        />
                      </Flex>
                    )}

                    <FormNavigationAndSubmit
                      formId={formId}
                      onPageChange={onPageChange}
                      setFormProgressSaveGuid={setFormProgressSaveGuid}
                      formResponse={formResponse}
                      isPreview={isPreview}
                      auth={{ authToken, password }}
                      manualSubmitBtnRef={manualSubmitBtnRef}
                      isDisabled={isCaptchaEnabled && !hasValidRecaptcha}
                      setErrorMsg={setErrorMsg}
                      onDisplayErrors={onDisplayErrors}
                    />

                    <Box as="pre" textAlign="left" fontSize="xs" className="unmand-form-input-errors" display="none">
                      {JSON.stringify(errors, null, 4)}
                    </Box>
                  </form>
                </Card>
              </Container>
            </FormAccessibilityProvider>
          </GoogleMapsProvider>
        </Box>
      ) : (
        <></>
      )}
      {isPrintMode && <SubmittedFormDetails formResponse={formResponse!} />}

      {isLastPage && (
        <Box position="absolute" left="-9999px" top="-99999px" visibility="hidden" w="100%">
          <Box id={SUBMITTED_DATA_BLOCK_ID} w="100%">
            <Flex w="100%" justifyContent="center">
              <Flex
                className="unmand-form-wrapper"
                flexDir="column"
                position="relative"
                minW={{ base: '0px', md: MIN_FORM_WIDTH }}
                w="100%"
                minH="100vh"
                maxW="800px"
              >
                <SubmittedFormDetails formResponse={formResponse!} />
              </Flex>
            </Flex>
          </Box>
        </Box>
      )}
    </Flex>
  )
}
