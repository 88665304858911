import { Box, Flex } from '@chakra-ui/react'
import { Responsive, WidthProvider } from 'react-grid-layout'
import { GRID_ROW_HEIGHT, TASK_DATA_GRID_COLUMNS, cols } from '../constants'
import { GoogleMapsProvider } from '../context'
import { FormAccessibilityProvider } from '../context/FormAccessibilityContext'
import { useFormState } from '../context/FormStateContext'
import { FormField, FormFieldTypes, FormResponse } from '../interfaces'
import { GeneralUtils, GroupedPageUtils, StyleUtils } from '../utils'
import { FormFieldInstance } from './field-inputs'

const ResponsiveGridLayout = WidthProvider(Responsive)

interface SubmittedFormDetailsProps {
  formResponse: FormResponse
}

export const SubmittedFormDetails: React.FC<SubmittedFormDetailsProps> = ({ formResponse }) => {
  const {
    formFields,
    fieldPages,
    hiddenPageIds,
    formMethods: {
      control,
      unregister,
      setValue,
      formState: { errors },
    },
  } = useFormState()

  const isMobile = GeneralUtils.isMobileBrowser()
  const { style } = formResponse ?? {}
  const { inputs, layout } = StyleUtils.getTheme(style ?? {})
  const displayedPages = formResponse.pages.filter(page => !hiddenPageIds.includes(page.guid))

  return (
    <>
      <GoogleMapsProvider pages={fieldPages}>
        <FormAccessibilityProvider fields={formFields}>
          {displayedPages.map(page => {
            const currentPageFields: FormField[] = fieldPages[page.guid]
            const fieldLayouts =
              fieldPages[page.guid]?.map(field => {
                let layout = field.layout!

                if (isMobile) {
                  layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
                }

                return layout
              }) ?? []

            let groupedFields = [currentPageFields]

            if (page.isGrouped) {
              const activeGroupedPageIndexes = Array.from(
                { length: GroupedPageUtils.getListIndexCount(currentPageFields) },
                (_, idx) => idx,
              )

              groupedFields = activeGroupedPageIndexes.map(idx => {
                return currentPageFields.filter((f: FormField) => f.listIndex === idx)
              })
            }

            return (
              <>
                {groupedFields.map(fields => {
                  return (
                    <Box w="100%">
                      <ResponsiveGridLayout
                        className="layout"
                        cols={cols}
                        margin={[16, layout.padding]}
                        rowHeight={GRID_ROW_HEIGHT}
                        isDraggable={false}
                        isResizable={false}
                        layouts={{
                          lg: fieldLayouts,
                          md: fieldLayouts,
                          sm: fieldLayouts,
                          xs: fieldLayouts,
                          xxs: fieldLayouts,
                        }}
                      >
                        {fields
                          .filter(f => !f.hideOnPrint)
                          .map(f => {
                            const field = f

                            let layout = field.layout
                            if (isMobile) {
                              layout = { ...layout, w: TASK_DATA_GRID_COLUMNS }
                            }

                            return (
                              <Box
                                key={field.key}
                                data-grid={layout}
                                role="group"
                                overflow={field.type === FormFieldTypes.MultiSelect ? 'auto' : 'initial'}
                              >
                                <Flex
                                  alignItems={field.type === FormFieldTypes.Markdown ? 'flex-start' : 'center'}
                                  gap={2}
                                  height="100%"
                                >
                                  <Box display="contents">
                                    <FormFieldInstance
                                      variant={inputs.variant}
                                      isDisabled={false}
                                      field={{ ...field, isReadonly: true } as any}
                                      control={control}
                                      errors={errors}
                                      isLabelHidden={false}
                                      defaultData={{}}
                                      unregister={unregister}
                                      setValue={setValue}
                                      style={style ?? {}}
                                      validationCount={0}
                                    />
                                  </Box>
                                </Flex>
                              </Box>
                            )
                          })}
                      </ResponsiveGridLayout>
                    </Box>
                  )
                })}
              </>
            )
          })}
        </FormAccessibilityProvider>
      </GoogleMapsProvider>
    </>
  )
}
