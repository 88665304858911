import { Parser } from 'expr-eval'
import { isEqual, isNil } from 'lodash'
import React, { PropsWithChildren, createContext, useContext, useEffect, useRef, useState } from 'react'
import { FieldError, MultipleFieldErrors, useWatch } from 'react-hook-form'
import {
  BasicField,
  Condition,
  ConditionShowError,
  ConditionTarget,
  ConditionType,
  DoOptionsEnableRequire,
  DoOptionsShowHide,
  DoOptionsSkipPage,
  FieldConditionMap,
  FormField,
  FormFieldPages,
  FormFieldTypes,
  NumericField,
  TEMP_PAGE_ID,
} from '../interfaces'
import { FormStateStore } from '../shared'
import { ConditionUtils, FormBuilderUtils, GeneralUtils } from '../utils'
import { useFormState } from './FormStateContext'

export type CustomFieldError = FieldError & { customError: boolean }

interface FormConditionsContextType {
  fieldConditionMap: FieldConditionMap
  setFieldConditionMap: (fieldConditionMap: FieldConditionMap) => void
  evaluatePageConditions: (newPage: string) => { nextPage: string; latestPages?: FormFieldPages }
  evaluateNextPageConditions: () => void
  setForceTrigger: (forceTrigger: boolean) => void
  validationCount: number
  setValidationCount: (validationCount: number) => void
}

const FormConditionsContext = createContext<FormConditionsContextType | undefined>(undefined)

export const FormConditionsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const {
    conditions,
    fieldPages,
    fieldPagesSOT,
    formMethods: {
      watch,
      getValues,
      formState,
      setError,
      setValue,
      control,
      unregister,
      formState: { isValidating },
    },
    hiddenPageIds,
    isLoading,
    newFields,
    onUpdateFormStructure,
    page,
    pages,
    setHiddenPageIds,
    updateValidationSchema,
  } = useFormState()

  const [validationCount, setValidationCount] = useState(0)
  const [fieldConditionMap, setFieldConditionMap] = useState<FieldConditionMap>({})
  const [forceTrigger, setForceTrigger] = useState(false)
  const [formulaFieldsGuids, setFormulaFieldsGuids] = useState<string[]>([])
  const [previousValues, setPreviousValues] = useState(watch(Object.keys(fieldConditionMap) as any))

  const conditionMapKeys = Object.keys(fieldConditionMap)
  const conditionSources = watch(conditionMapKeys)
  const sortedPages = GeneralUtils.sortPages(pages)

  const referencedFormulaFieldsValues = useWatch({
    name: formulaFieldsGuids as any,
    control,
  }) as unknown as number[]

  useEffect(() => {
    if (isValidating) {
      setValidationCount(validationCount + 1)
    }
  }, [isValidating])

  useEffect(() => {
    const fieldIds = conditionMapKeys
    if ((!fieldIds.length || isEqual(conditionSources, previousValues)) && !forceTrigger) {
      return
    }

    fieldIds.forEach((formFieldId: string, ndx: number) => {
      if (
        previousValues === undefined ||
        !previousValues?.length ||
        previousValues[ndx] !== conditionSources[ndx] ||
        forceTrigger
      ) {
        triggerConditionChecking(formFieldId)
      }
    })
    updateValidationSchema()
    setPreviousValues(conditionSources as any)

    if (forceTrigger) {
      setForceTrigger(false)
    }
  }, [conditionSources])

  const useDeepMemo = (value: any) => {
    const ref = useRef()

    if (!isEqual(value, ref.current)) {
      ref.current = value
    }
    return ref.current
  }

  const memoizedValues = useDeepMemo({
    fields: newFields,
    conditionKeys: conditionMapKeys,
  })

  useEffect(() => {
    newFields.forEach(field => triggerConditionChecking(field.guid!))
  }, [memoizedValues])

  useEffect(() => {
    if (conditions && fieldPagesSOT) {
      const allFormFields = Object.values(fieldPagesSOT).flat()
      const updatedConditionMap = ConditionUtils.generateFieldConditionMap(conditions, allFormFields)
      setFieldConditionMap(updatedConditionMap)
    }
  }, [conditions])

  useEffect(() => {
    if (!isLoading && fieldPages) {
      const allFormFields = Object.values(fieldPages).flat()
      const referencedFormulaFields = allFormFields.reduce((acc: string[], field) => {
        if ('formula' in field && !isNil(field.formula)) {
          return [...acc, ...FormBuilderUtils.substituteFormulaFields(field.formula, allFormFields, {}).fieldGuids]
        }
        return acc
      }, [])
      setFormulaFieldsGuids(referencedFormulaFields)
    }
  }, [isLoading])

  useEffect(() => {
    const referencedFormulaFields = Object.fromEntries(
      formulaFieldsGuids.map((fieldGuid, index) => [fieldGuid, referencedFormulaFieldsValues[index]]),
    )
    const formulaFields = Object.values(fieldPages)
      .flat()
      .filter(field => 'formula' in field && !isNil(field.formula)) as NumericField[]

    formulaFields.forEach(field => updateFormulaFields(field, referencedFormulaFields))
  }, [referencedFormulaFieldsValues])

  const initializeDefaultValues = (fields: FormField[]) => {
    fields.forEach(field => {
      if ('defaultValue' in field && !isNil(field.defaultValue) && field.defaultValue !== '') {
        setValue(field.key, field.defaultValue)
      }

      if (
        'type' in field &&
        field.type === FormFieldTypes.Datepicker &&
        'isDefaultDateToday' in field &&
        field.isDefaultDateToday
      ) {
        setValue(field.key, new Date().toISOString())
      }
    })
  }

  const handleConditionTargets = (condition: Condition, isConditionMet: boolean) => {
    const actions = {
      [ConditionType.SHOW_OR_HIDE]: isConditionMet ? handleShowHideAction : addRemovedFieldByTarget,
      [ConditionType.ENABLE_OR_REQUIRE]: isConditionMet ? handleEnableRequireAction : resetTargetToDefaultSettings,
      [ConditionType.SHOW_ERROR]: isConditionMet
        ? (target: ConditionShowError) => handleSetValidationError(target, condition.guid)
        : (target: ConditionShowError) => removeValidationError(target, condition.guid),
    }

    condition.target.forEach(target => actions[target.type]?.(target))
  }

  const triggerConditionChecking = (formFieldId: string) => {
    const fieldConditions = fieldConditionMap[formFieldId]
    if (!fieldConditions) return

    const values = getValues()
    fieldConditions.forEach(condition => {
      const isConditionMet = condition.source.reduce((result, source) => {
        const conditionResult = ConditionUtils.hasMetCondition(source, values)
        return result === null
          ? conditionResult
          : condition.operator === 'AND'
          ? result && conditionResult
          : condition.operator === 'OR'
          ? result || conditionResult
          : result
      }, null as boolean | null)

      handleConditionTargets(condition, isConditionMet as boolean)
    })
  }

  const handleShowHideAction = (target: ConditionTarget): void => {
    if (target.targetAction === DoOptionsShowHide.SHOW) {
      const targetFieldsSet = new Set(target.targetFieldIds)
      const fieldsToShow = Object.values(fieldPagesSOT)
        .flat()
        .filter(field => targetFieldsSet.has(field.guid!))

      initializeDefaultValues(fieldsToShow)

      return addRemovedFieldByTarget(target)
    } else if (target.targetAction === DoOptionsShowHide.HIDE) {
      const targetFieldsSet = new Set(target.targetFieldIds)
      const updatedPages = Object.fromEntries(
        Object.entries(FormStateStore.fieldPagesLocalRef).map(([pageKey, fields]) => [
          pageKey,
          fields.filter(field => !targetFieldsSet.has(field.guid!)),
        ]),
      )

      target.targetFieldIds.forEach((fieldGuid: string) => {
        if (formulaFieldsGuids.includes(fieldGuid)) {
          unregister(fieldGuid)
        }
      })

      onUpdateFormStructure(updatedPages)
    }
  }

  const addRemovedFieldByTarget = (target: ConditionTarget): void => {
    if (target.targetAction !== DoOptionsShowHide.SHOW && target.targetAction !== DoOptionsShowHide.HIDE) {
      return
    }

    const referencedFormulaFields = Object.fromEntries(
      formulaFieldsGuids.map((fieldGuid, index) => [fieldGuid, referencedFormulaFieldsValues[index]]),
    )

    const updatedPages = Object.fromEntries(
      Object.entries(fieldPagesSOT).map(([pageKey, pageFields]) => {
        const currentPageFields = FormStateStore.fieldPagesLocalRef[pageKey]
        const targetFields = pageFields.filter(
          field => target.targetFieldIds.includes(field.guid!) && !currentPageFields.some(f => f.guid === field.guid),
        )

        const newFields = [...currentPageFields, ...targetFields]

        targetFields.forEach(field => {
          if (
            (field.type === FormFieldTypes.NumericInput || field.type === FormFieldTypes.Currency) &&
            !!field.formula
          ) {
            updateFormulaFields(field as NumericField, referencedFormulaFields as any)
          }
        })

        return [pageKey, newFields]
      }),
    )
    onUpdateFormStructure(updatedPages)
  }

  const handleEnableRequireAction = (target: ConditionTarget): void => {
    if (target.type !== ConditionType.ENABLE_OR_REQUIRE) {
      return
    }

    const actionHandler = ({ isRequired, isReadonly }: { isRequired: boolean | null; isReadonly: boolean | null }) => {
      const targetFieldsSet = new Set(target.targetFieldIds)

      const updatedPages = Object.fromEntries(
        Object.entries(FormStateStore.fieldPagesLocalRef).map(([pageKey, pageFields]) => {
          pageFields.forEach(field => {
            if (targetFieldsSet.has(field.guid!)) {
              const basicField = field as BasicField
              if (isRequired !== null) {
                return ((basicField as BasicField).isRequired = isRequired)
              }
              if (isReadonly !== null) {
                return ((basicField as BasicField).isReadonly = isReadonly)
              }
            }
          })
          return [pageKey, pageFields]
        }),
      )
      onUpdateFormStructure(updatedPages)
    }

    if (target.targetAction === DoOptionsEnableRequire.DISABLE) {
      actionHandler({ isRequired: null, isReadonly: true })
    } else if (target.targetAction === DoOptionsEnableRequire.ENABLE) {
      actionHandler({ isRequired: null, isReadonly: false })
    } else if (target.targetAction === DoOptionsEnableRequire.REQUIRE) {
      actionHandler({ isRequired: true, isReadonly: null })
    } else if (target.targetAction === DoOptionsEnableRequire.DONT_REQUIRE) {
      actionHandler({ isRequired: false, isReadonly: null })
    }
  }

  const handleSetValidationError = (target: ConditionShowError, conditionGuid: string): void => {
    target.targetFieldIds.forEach((targetFieldId: any) => {
      setError(targetFieldId, {
        types: {
          ...formState.errors[targetFieldId]?.types,
          [conditionGuid]: target.message,
        } as MultipleFieldErrors,
        customError: true,
      } as CustomFieldError)
    })
  }

  const removeValidationError = (target: ConditionShowError, conditionGuid: string): void => {
    target.targetFieldIds.forEach((targetFieldId: any) => {
      const updatedErrors = { ...formState.errors[targetFieldId] }
      delete (updatedErrors as MultipleFieldErrors)?.types?.[conditionGuid]
      setError(targetFieldId, updatedErrors)
    })
  }

  const resetTargetToDefaultSettings = (target: ConditionTarget): void => {
    if (target.type !== ConditionType.ENABLE_OR_REQUIRE) {
      return
    }

    const updatedPages = Object.fromEntries(
      Object.entries(FormStateStore.fieldPagesLocalRef).map(([pageKey, pageFields]) => {
        const sotFieldMap = new Map(fieldPagesSOT[pageKey].map(f => [f.guid, f]))

        pageFields.forEach(field => {
          if (target.targetFieldIds.includes(field.guid!)) {
            const matchingField = sotFieldMap.get(field.guid) as BasicField
            if (matchingField) {
              ;(field as BasicField).isRequired = matchingField.isRequired
              ;(field as BasicField).isReadonly = matchingField.isReadonly
            }
          }
        })

        return [pageKey, pageFields]
      }),
    )

    onUpdateFormStructure(updatedPages)
  }

  const evaluateNextPageConditions = (): void => {
    const pageKeys = pages.map(p => p.guid)
    const currPageGuid = pageKeys.find(key => key === page)
    if (currPageGuid) {
      const nextPageGuid = ConditionUtils.getNewPageGuid({
        change: 'increment',
        currPageGuid,
        hiddenPageIds,
        pages: pageKeys,
      })
      if (nextPageGuid) {
        evaluatePageConditions(nextPageGuid)
      }
    }
  }

  const evaluatePageConditions = (newPage: string): { nextPage: string; latestPages?: FormFieldPages } => {
    const pageConditions = conditions.filter(condition => condition.type === ConditionType.SKIP_PAGE)
    const validFieldsSet = new Set(
      Object.values(fieldPages)
        .flat()
        .map(field => field.guid),
    )
    const hiddenPagesSet = new Set(hiddenPageIds)
    const latestPageKeysSet = new Set(Object.keys(FormStateStore.fieldPagesLocalRef))
    const pageKeysArray = sortedPages.map(p => p.guid).filter(id => latestPageKeysSet.has(id))
    const values = getValues()
    let nextPage = newPage
    let latestPages: FormFieldPages = FormStateStore.fieldPagesLocalRef

    pageConditions.forEach(condition => {
      const isConditionMet = condition.source.reduce((result, source) => {
        if (page && !validFieldsSet.has(source.formFieldId)) return result
        const conditionResult = ConditionUtils.hasMetCondition(source, values)
        return result === null
          ? conditionResult
          : condition.operator === 'AND'
          ? result && conditionResult
          : condition.operator === 'OR'
          ? result || conditionResult
          : result
      }, null as boolean | null)

      condition.target.forEach(target => {
        if (target.type !== ConditionType.SKIP_PAGE) {
          return
        }

        if (isConditionMet) {
          if (target.targetAction === DoOptionsSkipPage.SKIP_TO_PAGE) {
            nextPage = target.targetPageId
          } else if (target.targetAction === DoOptionsSkipPage.HIDE_PAGE) {
            hiddenPagesSet.add(target.targetPageId)

            if (fieldPages[target.targetPageId]) {
              fieldPages[target.targetPageId].forEach(field => {
                if (field.guid) {
                  unregister(field.guid)
                }
              })
            }

            if (nextPage === target.targetPageId) {
              const currentIndex = pageKeysArray.findIndex(pageKey => pageKey === nextPage)
              nextPage = pageKeysArray[currentIndex + 1]
            }

            // When the next page is the last page and it is hidden -> add an empty page to show the captcha &/ submit button
            if (
              nextPage === target.targetPageId &&
              pageKeysArray[pageKeysArray.length - 1] === target.targetPageId &&
              !pageKeysArray.includes(TEMP_PAGE_ID)
            ) {
              nextPage = TEMP_PAGE_ID
              latestPages = {
                ...latestPages,
                [TEMP_PAGE_ID as string]: [],
              }
              onUpdateFormStructure(latestPages)
            }
          }
        } else if (target.targetAction === DoOptionsSkipPage.HIDE_PAGE && hiddenPagesSet.has(target.targetPageId)) {
          hiddenPagesSet.delete(target.targetPageId)

          // Initialize default values for fields in the page being shown
          const fieldsInPage = fieldPagesSOT[target.targetPageId] || []
          initializeDefaultValues(fieldsInPage)

          nextPage = ConditionUtils.getNewPageGuid({
            change: 'increment',
            currPageGuid: page,
            hiddenPageIds: Array.from(hiddenPagesSet),
            pages: pageKeysArray,
          })
        }
      })
    })

    setHiddenPageIds(Array.from(hiddenPagesSet))

    return { nextPage, latestPages }
  }

  const updateFormulaFields = (field: NumericField, referencedFormulaFields: Record<string, number>) => {
    const { formula } = FormBuilderUtils.substituteFormulaFields(
      field.formula as string,
      Object.values(fieldPages).flat(),
      referencedFormulaFields,
    )

    try {
      const parser = new Parser()
      const parsedValue = parser.parse(formula).evaluate(referencedFormulaFields)
      setValue(field.guid!, parsedValue)
    } catch (e) {
      setValue(field.guid!, null)
    }
  }

  return (
    <FormConditionsContext.Provider
      value={{
        fieldConditionMap,
        setFieldConditionMap,
        evaluatePageConditions,
        evaluateNextPageConditions,
        setForceTrigger,
        validationCount,
        setValidationCount,
      }}
    >
      {children}
    </FormConditionsContext.Provider>
  )
}

export const useFormConditions = () => {
  const context = useContext(FormConditionsContext)
  if (context === undefined) {
    throw new Error('useFormConditions must be used within a FormStateProvider')
  }
  return context
}
